<template>
  <div>
    <div id="usermanagement">
      <div class="p-3">
        <app-title-content></app-title-content>
        <!-- <div class="row my-3">
          <div class="col-5">
            <b-form-input
              class="input-custom"
              placeholder="ชื่อ - นามสกุล"
            ></b-form-input>
          </div>
          <div class="col-5">
            <select2
              class="select-custom"
              :options="departmentList"
              v-model="department"
              placeholder="หน่วยงาน"
              :settings="{
                language: {
                  noResults: function () {
                    return 'ไม่พบข้อมูล';
                  },
                },
                allowClear: 'true',
              }"
            >
            </select2>
          </div>
          <div class="col-2">
            <button type="submit" class="btn btn-save w-100">ค้นหา</button>
          </div>
        </div> -->
        <div class="box-shadow p-4 mt-4">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-baseline">
              <div class="fontsize-h3 weight-700 mr-3 font-darkblue">
                รายการผู้ใช้งาน
              </div>
              <div class="fontsize-h5">จำนวน {{ total | toCommas }} รายการ</div>
            </div>
            <div class="d-flex">
              <router-link
                :to="{
                  name: 'AddUser',
                  params: {
                    mode: 'add',
                  },
                }"
                class="btn btn-addlist"
              >
                <font-awesome-icon
                  :icon="['fas', 'plus-circle']"
                  class="font-white mr-2"
                />
                เพิ่มผู้ใช้งาน
              </router-link>
            </div>
          </div>
          <div class="mt-3">
            <b-table
              id="my-table"
              hover
              borderless
              show-empty
              :no-border-collapse="true"
              :items="items"
              :fields="fields"
              :busy.sync="isBusy"
              class="table-custom box-shadow"
            >
              <template v-slot:empty="">
                <div class="text-center fontsize-h4 my-4">ไม่พบข้อมูล</div>
              </template>

              <div slot="table-busy" class="text-center font-blueinfo my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="pl-2">กำลังโหลด...</strong>
              </div>

              <!-- Row running number -->
              <template v-slot:cell(index)="data">
                <div class="text-center">
                  {{ (currentPage - 1) * perPage + (data.index + 1) }}
                </div>
              </template>

              <!-- header custom align -->
              <template v-slot:head()="data">
                <div
                  :class="
                    data.field.align === 'left'
                      ? 'text-left'
                      : data.field.align === 'right'
                      ? 'text-right'
                      : 'text-center'
                  "
                >
                  {{ data.label }}
                </div>
              </template>

              <!-- Row Custom align -->
              <template v-slot:cell()="data">
                <div
                  v-if="data.item.alignRow !== undefined"
                  :class="
                    data.item.alignRow === 'left'
                      ? 'text-left'
                      : data.item.alignRow === 'right'
                      ? 'text-right'
                      : 'text-center'
                  "
                >
                  {{ data.value }}
                </div>
                <div
                  v-else
                  :class="
                    data.field.align === 'left'
                      ? 'text-left'
                      : data.field.align === 'right'
                      ? 'text-right'
                      : 'text-center'
                  "
                >
                  {{ data.value }}
                </div>
              </template>

              <template #cell(canEdit)="row">
                <template v-if="row.item.canEdit">
                  <div class="text-center">
                    <font-awesome-icon
                      :icon="['fas', 'edit']"
                      @click="editRow(row.item.userID)"
                      class="font-yellow cursor-pointer"
                    />
                  </div>
                </template>
                <template v-else>
                  <div class="text-center">
                    <font-awesome-icon
                      :icon="['fas', 'edit']"
                      style="opacity: 0.5"
                      class="text-dark"
                    />
                  </div>
                </template>
              </template>
              <template #cell(canDelete)="row">
                <template v-if="row.item.canEdit">
                  <div class="text-center">
                    <font-awesome-icon
                      :icon="['fas', 'trash']"
                      @click="deleteRow(row.item.userID)"
                      class="font-reddanger cursor-pointer"
                    />
                  </div>
                </template>
                <template v-else>
                  <div class="text-center">
                    <font-awesome-icon
                      :icon="['fas', 'trash']"
                      style="opacity: 0.5"
                      class="text-dark"
                    />
                  </div>
                </template>
              </template>
            </b-table>
            <b-pagination
              class="pagination-custom mt-3"
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              aria-controls="my-table"
              align="right"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dataSource } from "./items.js";
import { FilterService, UserService } from "@/services/main.service.js";

export default {
  name: "user-management",
  data() {
    return {
      departmentList: [],
      department: null,
      items: [],
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "userFullName",
          label: "ชื่อ - นามสกุล",
          align: "left",
        },
        {
          key: "userName",
          label: "Username",
          align: "left",
        },
        {
          key: "departmentName",
          label: "หน่วยงาน",
          align: "left",
        },
        {
          key: "canEdit",
          label: "แก้ไข",
        },
        {
          key: "canDelete",
          label: "ลบ",
        },
      ],
      total: 0,
      currentPage: 1,
      perPage: 50,
      isBusy: false,
      userReporter: process.env.VUE_APP_REPORTER_USER,
    };
  },
  async created() {
    // this.getDepartment();

    await this.getListUser();
  },
  methods: {
    async changePage(event) {
      this.currentPage = event;
      //call service ...
      await this.getListUser();
    },
    async getDepartment() {
      this.departmentList = await FilterService.getFilterDepartment().then(
        (res) => {
          return res;
        }
      );
    },
    async getListUser() {
      this.isBusy = true;
      const param = {
        NumPage: this.currentPage,
        NumRow: this.perPage,
      };

      await UserService.getListUser(param)
        .then((res) => {
          this.items = res.data.map((m) => {
            const _check = m.userName !== this.userReporter;
            return {
              ...m,
              canEdit: _check,
              canDelete: _check,
            };
          });

          this.total = res.total;
        })
        .catch((err) => {
          this.items = [];
          this.total = 0;
          this.currentPage = 1;
        })
        .finally((f) => {
          this.isBusy = false;
        });
    },
    editRow(id) {
      this.$router.push({
        name: "EditUser",
        params: {
          mode: "edit",
          userId: id,
        },
      });
    },
    deleteRow(id) {
      const isLastRowInLastPage = this.checkLastPage();

      this.alertDeleteForm().then(async (result) => {
        if (result) {
          await UserService.postDeleteUser(id)
            .then((res) => {
              if (res && res?.status) {
                //alert success
                this.alertSuccess("ลบรายการสำเร็จ");
                if (isLastRowInLastPage) this.currentPage--;
                //refresh list
                this.getListUser();
              } else {
                //alert fail
                this.alertFail("ลบรายการไม่สำเร็จ");
              }
            })
            .catch((err) => {
              //alert fail
              this.alertFail("ลบรายการไม่สำเร็จ");
            });
        }
      });
    },
    checkLastPage() {
      const total = this.total;
      const perPage = this.perPage;
      const currentPage = this.currentPage;
      // const isLastpage = total / perPage === currentPage;
      const isLastRowInLastPage = total === perPage * (currentPage - 1) + 1;
      return isLastRowInLastPage;
    },
  },
};
</script>

<style></style>
