var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"usermanagement"}},[_c('div',{staticClass:"p-3"},[_c('app-title-content'),_c('div',{staticClass:"box-shadow p-4 mt-4"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex align-items-baseline"},[_c('div',{staticClass:"fontsize-h3 weight-700 mr-3 font-darkblue"},[_vm._v(" รายการผู้ใช้งาน ")]),_c('div',{staticClass:"fontsize-h5"},[_vm._v("จำนวน "+_vm._s(_vm._f("toCommas")(_vm.total))+" รายการ")])]),_c('div',{staticClass:"d-flex"},[_c('router-link',{staticClass:"btn btn-addlist",attrs:{"to":{
                name: 'AddUser',
                params: {
                  mode: 'add',
                },
              }}},[_c('font-awesome-icon',{staticClass:"font-white mr-2",attrs:{"icon":['fas', 'plus-circle']}}),_vm._v(" เพิ่มผู้ใช้งาน ")],1)],1)]),_c('div',{staticClass:"mt-3"},[_c('b-table',{staticClass:"table-custom box-shadow",attrs:{"id":"my-table","hover":"","borderless":"","show-empty":"","no-border-collapse":true,"items":_vm.items,"fields":_vm.fields,"busy":_vm.isBusy},on:{"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center fontsize-h4 my-4"},[_vm._v("ไม่พบข้อมูล")])]},proxy:true},{key:"cell(index)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s((_vm.currentPage - 1) * _vm.perPage + (data.index + 1))+" ")])]}},{key:"head()",fn:function(data){return [_c('div',{class:data.field.align === 'left'
                    ? 'text-left'
                    : data.field.align === 'right'
                    ? 'text-right'
                    : 'text-center'},[_vm._v(" "+_vm._s(data.label)+" ")])]}},{key:"cell()",fn:function(data){return [(data.item.alignRow !== undefined)?_c('div',{class:data.item.alignRow === 'left'
                    ? 'text-left'
                    : data.item.alignRow === 'right'
                    ? 'text-right'
                    : 'text-center'},[_vm._v(" "+_vm._s(data.value)+" ")]):_c('div',{class:data.field.align === 'left'
                    ? 'text-left'
                    : data.field.align === 'right'
                    ? 'text-right'
                    : 'text-center'},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(canEdit)",fn:function(row){return [(row.item.canEdit)?[_c('div',{staticClass:"text-center"},[_c('font-awesome-icon',{staticClass:"font-yellow cursor-pointer",attrs:{"icon":['fas', 'edit']},on:{"click":function($event){return _vm.editRow(row.item.userID)}}})],1)]:[_c('div',{staticClass:"text-center"},[_c('font-awesome-icon',{staticClass:"text-dark",staticStyle:{"opacity":"0.5"},attrs:{"icon":['fas', 'edit']}})],1)]]}},{key:"cell(canDelete)",fn:function(row){return [(row.item.canEdit)?[_c('div',{staticClass:"text-center"},[_c('font-awesome-icon',{staticClass:"font-reddanger cursor-pointer",attrs:{"icon":['fas', 'trash']},on:{"click":function($event){return _vm.deleteRow(row.item.userID)}}})],1)]:[_c('div',{staticClass:"text-center"},[_c('font-awesome-icon',{staticClass:"text-dark",staticStyle:{"opacity":"0.5"},attrs:{"icon":['fas', 'trash']}})],1)]]}}])},[_c('div',{staticClass:"text-center font-blueinfo my-2",attrs:{"slot":"table-busy"},slot:"table-busy"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',{staticClass:"pl-2"},[_vm._v("กำลังโหลด...")])],1)]),_c('b-pagination',{staticClass:"pagination-custom mt-3",attrs:{"total-rows":_vm.total,"per-page":_vm.perPage,"aria-controls":"my-table","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }